import apiClient from "./apiClient";

class NewsClient {
  // 取得推薦延伸新聞
  getExtendNews = (newsId, locale) =>
    apiClient.get(
      `/news/${newsId}/extends?limit=4&existExtendNewsIds=${newsId}`,
      {
        headers: {
          "X-LANG": "zh-" + locale,
        },
      }
    );

  // 取得個類別新聞
  getNewsListByCategory = (locale, category, pageIndex = 0, pageSize = 12) =>
    apiClient.get(
      `/news/categories/${category}/page?pageIndex=${pageIndex}&pageSize=${pageSize}`,
      {
        headers: {
          "X-LANG": "zh-" + locale,
        },
      }
    );

  // 取得各分類熱門新聞 (首頁8則 、 新聞列表頁 5 則)
  getPopularNewsByCategoryCode = (locale, categoryCode = "Hot", pageSize = 8) =>
    apiClient.get(
      `/news/popular?categoryCode=${categoryCode}&pageIndex=0&pageSize=${pageSize}&inDays=1`,
      {
        headers: {
          "X-LANG": "zh-" + locale,
        },
      }
    );

  // 取得學院列表
  getAcademyList = (locale) =>
    apiClient.get(`/academy?pageIndex=0&pageSize=12`, {
      headers: {
        "X-LANG": "zh-" + locale,
      },
    });

  // 取得更多新聞內容
  getMoreNews = (locale, newsId, id) =>
    apiClient.get(
      `/news/${newsId}/prev`,
      { params: { id } },
      {
        headers: {
          "X-LANG": "zh-" + locale,
        },
      }
    );
}

const newsClient = new NewsClient();

export default newsClient;

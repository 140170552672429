import axios from 'axios';
import FormData from 'form-data';

// const baseURL = `http://localhost:3001/api/v1`;
export const baseURL = '/api/v1';

const instance = axios.create({
    baseURL,
    timeout: 300000,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a response interceptor
instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
        if (error && error.response) {
            // console.error('axios request error. message:', error.response.data.message);
            // switch (error.response.status) {
            //     case 401:
            //         // do something
            //         break
            //     case 404:
            //         // do something
            //         break
            //     case 500:
            //         // do something
            //         break
            //     default:
            //         console.log(error.response)
            //         break
            // }
            return Promise.reject(error.response.data);
        }

        return Promise.reject(error);
    }
);

// Add a request interceptor
instance.interceptors.request.use(
    (config) => {
        // config 詳細
        // https://axios-http.com/docs/req_config
        if (config.data instanceof FormData) {
            config.headers = {
                ...config.headers,
                'Content-Type': 'multipart/form-data',
            };
        }

        if (config.method === 'post' || config.method === 'put') {
            config.headers = {
                ...config.headers,
                // authorization: "Bearer <token>",
            };
        }

        // CORS: 若需要呼叫獨音的 api server
        // config.withCredentials = true;

        return config;
    },
    (error) => Promise.reject(error)
);

export default instance;
